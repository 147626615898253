import React from "react";

const Marquee = ({ text, speed = "50s" }) => {
  return (
    <div className="marquee">
      <ul className="marquee__content">
      <li>LOCAL Services</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>CALENDAR</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>community</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>RECOMMENDATIONS</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>BOOKING</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
      </ul>
      
      <ul className="marquee__content" aria-hidden="true">
      <li>LOCAL Services</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>CALENDAR</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>community</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>RECOMMENDATIONS</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
        <li>BOOKING</li>
        <li>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32389 0.228713L10.5661 7.7023L18.0433 8.92267L10.5697 10.1649L9.34937 17.6421L8.10712 10.1685L0.62993 8.94815L8.10351 7.7059L9.32389 0.228713Z"
              fill="black"
            />
          </svg>
        </li>
      </ul>
    </div>
  );
};

export default Marquee;
