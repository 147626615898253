import React from "react";

function App() {
  return (
    <div className="terms">
        <p><strong>TERMS OF USE</strong></p>
        <p><strong>Last Updated: May 23, 2022</strong></p>
        <p>These Terms of Use (&ldquo;<strong>Terms</strong>&rdquo;) govern your access to and use of this website, related software applications (referred to as &ldquo;apps&rdquo;), data, SMS, APIs, email, chat, and telephone correspondence, buttons, widgets, and ads (including all content and underlying technology, the &ldquo;<strong>Site</strong>&rdquo;), which is made available by League Life LLC (&ldquo;<strong>LEAGUE LIFE</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;).</p>
        <p><strong>The Site is offered to you conditioned upon your acceptance of the terms, conditions, and notices set forth in these Terms and in our Privacy Policy.&nbsp; By accessing or using the Site, you agree that you have read, understand, and agree to be bound by these Terms and our </strong><strong>Privacy Policy</strong><strong>. The term &ldquo;you&rdquo; refers to the individual, company, business organization, or other legal entity using the Site and/or contributing to the Site. If you do not agree with these Terms or our </strong><strong>Privacy Policy</strong><strong>, do not access or use the Site.</strong></p>
        <p><strong>We reserve the right to modify these Terms at any time. All changes will be effective immediately upon posting to the Site and, by accessing or using the Site after changes are posted, you agree to be bound by those changes. Material changes will be prominently posted on the Site or otherwise communicated to you.</strong></p>
        <ol>
            <li><strong>Privacy Policy. </strong>We may collect certain information about you and from your use of the Site as described in our Privacy Policy, which is incorporated into and made a part of these Terms. Your use of the Site constitutes your consent to the information collection, use, and sharing described in our Privacy Policy.&nbsp;</li>
            <li><strong>Permitted Use.</strong> Provided you comply with these Terms, you may access and use the Site solely for your own personal purposes and, in any event, in accordance with all related documentation or restrictions posted on the Site or otherwise provided by LEAGUE LIFE.</li>
            <li><strong>Intellectual Property Rights. </strong>The Site, including its text, links, graphics, photos, audio, videos, charts, interfaces, icons, software, computer code, data, trademarks, logos, slogans, names of products, and services, documentation, other components and content, and the design, selection, and arrangement of content (collectively, &ldquo;Content&rdquo;) are exclusively the property of LEAGUE LIFE or, as applicable, its suppliers and licensors, and are protected by copyright, trademark, and other intellectual property laws. Any unauthorized use of the Content or any other intellectual property rights belonging to LEAGUE LIFE or any third party is prohibited and may be prosecuted to the fullest extent of the law.</li>
        </ol>
        <p>No licenses or other rights, express or implied, are granted by LEAGUE LIFE to you under any patent, copyright, trademark, trade secret, or other intellectual property right of LEAGUE LIFE and all such rights are reserved and retained by LEAGUE LIFE.&nbsp; Copying, transmission, reproduction, replication, posting, or redistribution of (a) Content or any portion thereof and/or (b) the Site more generally is strictly prohibited without the prior written permission of LEAGUE LIFE. To request permission, please direct your request as set forth below. &nbsp;</p>
        <p>Copyright &copy; 2022 LEAGUE LIFE LLC. All rights reserved.&nbsp;</p>
        <p>The Site may contain, reference, and make available third-party Content that is the property of their respective owners.</p>
        <ol start="4">
            <li><strong>Your Warranties. </strong>As a condition of your access and use of the Site, you represent and warrant that:</li>
        </ol>
        <ol>
            <li>
                <ul>
                    <li>You have the legal authority to: enter into these Terms; use and/or contribute to the Site, as applicable; and bind any entity that you represent;</li>
                    <li>You are under no contractual obligation that will interfere with your ability to perform under these Terms;</li>
                    <li>You will not access or use the Site in any manner not permitted by these Terms;</li>
                    <li>You will perform your obligations and exercise your rights under these Terms in compliance with all applicable laws and regulations (including any applicable rules regarding online conduct);</li>
                    <li>All information you provide in connection with your access to or use of the Site is true, accurate, current, and complete;</li>
                    <li>You will safeguard your account information and will supervise and be completely responsible for any use of your account by anyone other than you;&nbsp;</li>
                    <li>You are 13 years of age or older (in some jurisdictions, local laws may have an older age requirement) in order to register for an account, use the Services , and contribute to the Site.</li>
                </ul>
            </li>
        </ol>
        <ol start="5">
            <li><strong>Use Restrictions.</strong> The Site is provided solely to: assist customers in gathering information, review Content, and search for and book services and reservations; and assist travel, tourism, and hospitality businesses in engaging with customers and potential customers, by way of free and/or paid-for services offered by or through LEAGUE LIFE.&nbsp; You will NOT, nor will you permit any other person to:</li>
        </ol>
        <ol>
            <li>
                <ul>
                    <li>Use the Site or Content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this Agreement and according to related guidelines as made available by LEAGUE LIFE;&nbsp;</li>
                    <li>Copy, download, reproduce, display, duplicate, sell, publish, disclose, post, license, rent, distribute, reconfigure, reverse-engineer, disassemble, decompile, prepare any derivative works of, discover the source code of, combine with other computer code or materials, translate, adapt, update, or modify the Site or any of its Content;</li>
                    <li>Obscure, remove, alter, or modify any copyright, trademark, or other proprietary markings, designations, or notices in or on the Site;</li>
                    <li>Access or use the Site for unlawful purposes;</li>
                    <li>Access or use the Site using means not authorized by LEAGUE LIFE;</li>
                    <li>Provide inaccurate, incomplete, or out-of-date information via the Site;</li>
                    <li>Create a false identify, hide your true identity, or impersonate or attempt to impersonate any person, business, or Site user other than yourself;</li>
                    <li>Commit fraud or falsify information in connection with your use of the Site;</li>
                    <li>Scrape, or otherwise engage in data mining or similar data gathering or extraction activities or retrieve data or other content from the Site for purposes of creating or compiling that content for any purpose;</li>
                    <li>Violate any restrictions in any exclusion headers on the Site or bypass or circumvent other measures employed to prevent or limit access to the Site;</li>
                    <li>Access or use the Site to develop, create, produce, enhance, or add to any database;</li>
                    <li>&ldquo;Frame,&rdquo; &ldquo;mirror,&rdquo; access, use, or copy any portion of the Site, including any of its content, through the use of indexing agents, spiders, scrapers, bots, web crawlers, or other automated devices or mechanisms;</li>
                    <li>Circumvent, disable, or otherwise interfere with security-related features of the Site or features that prevent or restrict use or copying of any Content;</li>
                    <li>Use the Site, including any of their content, in any way that infringes on or violates the rights of any other person or entity;</li>
                    <li>Submit or provide through use of the Site any information or content that is defamatory, libelous, indecent, pornographic, obscene, otherwise objectionable or harmful, or that violates the rights of third parties;</li>
                    <li>Post, transmit, input, upload, or otherwise provide any information or materials that contain any viruses, worms, Trojan horses, logic bombs, time bombs, cancelbots, malware, ransomware, adware, or other harmful computer code or programming routines, including those designed to or that reasonably may disable, damage, impair, interfere with, surreptitiously intercept, or expropriate the Site or any computers, hardware, software, system, data, or networks;</li>
                    <li>Engage in activities that aim to render the Site or associated services inoperable or to make their use more difficult;</li>
                    <li>Access, use, or analyze the Site for any purpose that is to LEAGUE LIFE&rsquo;s detriment or commercial disadvantage; nor</li>
                    <li>Act maliciously against the business interests or reputation of LEAGUE LIFE or any Site user.</li>
                </ul>
            </li>
        </ol>
        <p>In the event that we believe or determine that you have breached any of the aforementioned, we reserve the right to suspend and/or permanently deactivate your account at our sole discretion. &nbsp;</p>
        <ol start="6">
            <li><strong>Your Account.</strong> You must have an account with LEAGUE LIFE in order to log in the customer portal section of the Site (where that option is available). Authorized users of LEAGUE LIFE customers must be assigned login credentials by LEAGUE LIFE before they can log in to the customer account. Authorized users will be required to submit certain information and to establish a username and password in order to set up (or activate) your account. You are responsible for maintaining the confidentiality of any information you use in connection with your customer account or the Site, including your password. You are responsible for all activity on your account. If you believe someone else has accessed your account, please change your password and notify us immediately as set forth below. You must also notify us immediately if you are contacted by anyone requesting your username or password.</li>
            <li><strong>Confidentiality.</strong> To the extent you obtain or otherwise have access to any business information or other confidential information of LEAGUE LIFE, you shall not disclose or use any such confidential information for any purpose except as necessary for you to perform under these Terms or as contemplated in Section 5.&nbsp; &ldquo;Business information or other confidential information&rdquo; expressly includes any access code provided to you to access or use the Site, the Content of the Site, and any information you learn from access or use of the Site that would be reasonably construed as confidential.&nbsp; For the avoidance of doubt, you shall not share any access code provided to you to any other person, unless expressly authorized in writing by LEAGUE LIFE. &nbsp;</li>
            <li><strong>Suspension and Termination of Access.</strong> LEAGUE LIFE may, for any reason and in its sole discretion, suspend, deactivate, or terminate your use of the Site, and may terminate these Terms, without notice or liability, including if you breach these Terms or any related agreement with LEAGUE LIFE, if you act in an abusive manner, or if you act in a manner inconsistent with applicable laws or regulations.</li>
            <li><strong>Orders and Payment. </strong>Any and all purchases, reservations, bookings, and payments made through the Site are, as applicable, subject to additional terms of sale.<strong> </strong>All purchases, reservations, bookings, and payments made through the Site must be made by credit or debit card unless other options are made available to you. If the payment information you submit is incorrect or invalid, your payment will not be processed. We have no responsibility or liability if your credit or debit card is declined by your financial institution. Payments are processed by our applicable third-party payment processor. Information about our collection and use of payment-related information is described in our Privacy Policy.</li>
            <li><strong>Merchants Are Independent.</strong> You understand and agree that LEAGUE LIFE provides the Site connecting you with independent food, lifestyle, and wellness service providers and others that provide the services offered through the Site (&ldquo;Merchants&rdquo;). You acknowledge and agree that LEAGUE LIFE does not itself provide any food, lifestyle, or wellness services, and has no responsibility or liability for the acts or omissions of any Merchant. Merchant is the retailer; the services offered by LEAGUE LIFE pursuant to these Terms do not include any retail services, or any food, lifestyle, or wellness services. LEAGUE LIFE provides the Site facilitating the transmission of recommendations and reservations by you to Merchants. LEAGUE LIFE will not assess, guarantee, or be liable for the suitability, legality, or ability of any Merchant. You agree that LEAGUE LIFE is not responsible for the Merchants&rsquo; service preparation, service safety, or service accuracy, and does not verify Merchants&rsquo; compliance with applicable laws or regulations. LEAGUE LIFE has no responsibility or liability for acts or omissions by any Merchant. You agree that the services that you purchase will be prepared and provided by the Merchant you have selected. &nbsp;</li>
            <li><strong>Service Descriptions.</strong> LEAGUE LIFE strives to provide accurate information on services provided on the Site. However, LEAGUE LIFE does not represent or warrant that service descriptions, technical specifications, or other information are accurate, complete, reliable, or error-free. You hereby waive any claim against and release LEAGUE LIFE from any liability arising from or relating to the description of the services on the Site. &nbsp;</li>
            <li><strong>Site Availability; Changes to the Site.</strong> Any features, materials, or services made available through the Site are subject to availability. LEAGUE LIFE may change the Site, including features, materials, and services made available through the Site, at any time without notice or liability.</li>
            <li><strong>DISCLAIMER OF WARRANTIES</strong><strong>.</strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITE IS PROVIDED &ldquo;AS IS,&rdquo; &ldquo;AS AVAILABLE,&rdquo; WITH ALL FAULTS, AND WITHOUT ANY WARRANTY OF ANY KIND AND LEAGUE LIFE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE SITE (EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE), INCLUDING WITHOUT LIMITATION THOSE REGARDING AVAILABILITY, QUALITY, ACCURACY, MERCHANTABILITY, SUITABILITY, FITNESS FOR ANY USE OR PURPOSE, COMPATIBILITY WITH ANY STANDARDS OR USER REQUIREMENTS, TITLE, AND NONINFRINGEMENT, AS WELL AS ANY ARISING BY OPERATION OF LAW OR FROM A COURSE OF DEALING OR USAGE IN TRADE.</li>
        </ol>
        <p>LEAGUE LIFE DOES NOT WARRANT OR GUARANTEE THE QUALITY, COMPLETENESS, TIMELINESS, OR AVAILABILITY OF THE SITE, NOR DOES LEAGUE LIFE WARRANT OR GUARANTEE THAT ANY DATA, INFORMATION, OR MATERIAL ON THE SITE IS ACCURATE, RELIABLE, OR COMPLETE. LEAGUE LIFE DOES NOT WARRANT OR GUARANTEE THAT AVAILABILITY OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY DEFECTS IN THE SITE WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL CONDITIONS OR COMPONENTS. LEAGUE LIFE IS NOT RESPONSIBLE FOR ANY TYPOGRAPHICAL ERRORS ON OR RELATED TO THE SITE. LEAGUE LIFE HAS NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MISDELIVERY, OR FAILURE TO STORE ANY USER COMMUNICATION.</p>
        <p>WE MAKE NO REPRESENTATION THAT THE SITE IS APPROPRIATE OR AVAILABLE FOR USE IN LOCATIONS OTHER THAN THE UNITED STATES OR CANADA. IF YOU CHOOSE TO ACCESS OR USE THE SITE FROM LOCATIONS OTHER THAN THE UNITED STATES OR CANADA, YOU DO SO AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAWS AND REGULATIONS.</p>
        <p>NO ADVICE OR INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM LEAGUE LIFE OR IN ANY MANNER FROM THE SITE CREATES ANY WARRANTY.</p>
        <p>LEAGUE LIFE MARKS NO REPRESENTATIONS, WARRANTIES, OR COMMITMENTS RELATED TO MERCHANTS OR SERVICES. WE ACCEPT NO LIABILITY FOR ENSURING THAT SAID SERVICES ARE APPROPRIATE FOR YOUR OBJECTIVES. YOU ALONE ASSUME RESPONSIBILITY FOR THIS. YOU AGREE THAT YOU WILL MAKE USE OF THE SITE AND SERVICES AT YOUR OWN RISK. YOU UNDERSTAND AND ACCEPT THAT LEAGUE LIFE IS NOT RESPONSIBLE OR LIABLE FOR ANY MISUSE OR UNAUTHORIZED USE OF THE SITE OR SERVICES, OR FOR ANY CONSEQUENCES ARISING OUT OF SUCH MISUSE OR UNAUTHORIZED USE. &nbsp;</p>
        <ol start="14">
            <li><strong>LIMITATION OF LIABILITY</strong><strong>.</strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LEAGUE LIFE, ITS AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, OWNERS, EMPLOYEES, CONTRACTORS, REPRESENTATIVES, CONSULTANTS, AGENTS, SUPPLIERS, LICENSORS, SUCCESSORS, OR ASSIGNS (TOGETHER, THE &ldquo;<strong>LEAGUE LIFE PARTIES</strong>&rdquo;) BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSS OR DAMAGE, OR ANY OTHER LOSS OR DAMAGE OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH THE SITE, CONTENT, OR YOUR ACCESS TO OR USE OF (OR INABILITY TO ACCESS OR USE) THE SITE OR CONTENT, WHETHER THE CLAIM IS BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, WARRANTY, OR OTHERWISE, AND EVEN IF A LEAGUE LIFE PARTY HAS EXPRESS KNOWLEDGE OF THE POSSIBILITY OF THE LOSS OR DAMAGE. WITHOUT LIMITING THE FOREGOING, THE LEAGUE LIFE PARTIES SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE OF ANY KIND FOR ANY: LOSS OF PROFITS, USE DATA, REVENUES, BUSINESS OPPORTUNITIES, OR GOODWILL; INACCURATE INFORMATION ABOUT A MERCHANT OR SERVICES; OR SERVICE OR ACTION OF A MERCHANT.&nbsp; YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP ACCESSING AND USING THE SITE. WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL THE LEAGUE LIFE PARTIES&rsquo; LIABILITY TO YOU EXCEED U.S.D. $10, EVEN IF THIS REMEDY FAILS OF ITS ESSENTIAL PURPOSE. &nbsp;</li>
            <li><strong>Indemnification.</strong> You will indemnify, defend, and hold harmless the LEAGUE LIFE Parties from and against all losses, claims, liabilities, demands, complaints, actions, damages, judgments, settlements, fines, penalties, expenses, and costs (including reasonable attorneys&rsquo; fees) that arise out of or in connection with: (a) your access to or use of the Site; (b) your misuse of or reliance on any Content, material, data, or information viewed, accessed, obtained from, or used on the Site; (c) your submission of information through or using the Site; (d) your violation of or noncompliance with applicable laws or regulations; (e) your breach of these Terms or any related agreement with LEAGUE LIFE; or (f) your negligence, willful misconduct, or fraud. We reserve, and you grant to us, the exclusive right to assume the defense and control of any matter subject to indemnification by you.</li>
            <li><strong>Third-Party Websites and Site.</strong> The Site may link to, or be linked to, websites and services not maintained or controlled by LEAGUE LIFE. Those links are provided as a convenience and LEAGUE LIFE is not responsible for examining or evaluating the content or accuracy of, and does not warrant or endorse, any third-party website or services or any products or services made available through those websites or services. Please take care when leaving the Site to visit a third-party website or service. You should read the terms of use and privacy policy for each website and service that you use.</li>
            <li><strong>Feedback.</strong> LEAGUE LIFE welcomes comments regarding the Site. If you submit comments or feedback regarding the Site, they will not be considered or treated as confidential. We may use any comments and feedback that you send us in our discretion and without attribution or compensation to you.</li>
            <li><strong>Governing Law; Dispute Resolution.</strong> These Terms are governed by the laws of the state of Ohio, without regard to its conflict of law principles, and, as applicable, the federal laws of the United States. Venue is exclusively in the state or federal courts, as applicable, located in Cuyahoga County, Ohio, with respect to any dispute arising under these Terms unless otherwise determined by LEAGUE LIFE in its sole discretion. The parties expressly agree to the exclusive jurisdiction of those courts. If there is a dispute, the prevailing party will be entitled to recovery of its costs and expenses, including reasonable attorneys&rsquo; fees. You will not bring any action or other claim with respect to the Site or these Terms more than one (1) year after the claim arises.</li>
            <li><strong>Relationship of the Parties.</strong> LEAGUE LIFE is an independent contractor. These Terms do not create any agency, partnership, joint venture relationship, other form of joint enterprise, employment, or fiduciary relationship between the parties, their affiliates, or their respective employees, contractors, or agents. Neither party has any authority to contract for or bind the other party in any manner or make any representation or commitment on behalf of the other party.</li>
            <li><strong>Assignment.</strong> We may assign our rights and delegate our duties under these Terms at any time to any party without notice to you. You will not assign these Terms without our prior written consent. Any attempted assignment by you without our prior written consent will be void. Subject to the foregoing, these Terms are binding upon and inure to the benefit of the parties&rsquo; respective successors and assigns.</li>
            <li><strong>Entire Agreement; Interpretation. </strong>These Terms, including our Privacy Policy, are the entire agreement between you and LEAGUE LIFE with respect to your access to and use of the Site. LEAGUE LIFE&rsquo;s failure to enforce any provision of these Terms will not constitute a waiver of that provision or any other provision. Any waiver of any provision of these Terms will be effective only if in writing and signed by LEAGUE LIFE.</li>
        </ol>
        <p>Your access to or use of certain features or components of the Site may be subject to additional terms, conditions, rules, or policies. All such additional terms are incorporated by reference into these Terms.</p>
        <p>If any provision of these Terms is held to be invalid or unenforceable in any jurisdiction, that provision will be ineffective in that jurisdiction to the extent of the invalidity or unenforceability, without invalidating any other provision of these Terms. The headings in these Terms are for convenience only and will not affect the construction or interpretation of these Terms.</p>
        <p>These Terms do not confer any rights, remedies, or benefits upon any person other than you and LEAGUE LIFE. Any provisions of these Terms that are intended to survive termination (including any provisions regarding indemnification, limitation of our liability, or dispute resolution) will continue in effect beyond any termination of these Terms or of your access to or use of the Site.</p>
        <ol start="22">
            <li><strong>Electronic Communications.</strong> These Terms and any other documentation, agreements, notices, or communications between you and LEAGUE LIFE may be provided to you electronically to the extent permissible by law. Please print or otherwise save a copy of these Terms and all notices, consents, and other communications for your reference.</li>
            <li><strong>Contact Us.</strong> If you have any questions and concerns regarding these Terms or the Site, please contact us at:</li>
        </ol>
        <p>&nbsp;</p>
    </div>
  );
}

export default App;
