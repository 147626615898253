import React from "react";

function App() {
  const faqs = [
    {
      question: "Can I use the League Life App for all 30 NBA markets?",
      answer:
        "Yes, once you become a League Life member you have access all of the service providers by category for all 30 NBA markets.",
    },
    {
      question:
        "Is my information and search history private on the League Life App? Will you share my information with anyone?",
      answer:
        "Yes, League Life has gone to great lengths to make sure that we provide the highest level of security and privacy for our members. Information will not be shared with anyone.",
    },
    {
      question:
        "Can I recommend a business to be listed on the League Life App?",
      answer:
        "Yes, we would love to hear from you! On your profile page simply click \"Make a Suggestion\" and we will check it out.",
    },
    {
      question:
        "How can I provide feedback, comments or reviews about a business listed on League Life?",
      answer:
        "If you click on any service provider you will be able to add “comments” on their page within the app. Comments will be reviewed by the League Life staff before being posted.",
    },
    {
      question: "Why should I become a League Life member?",
      answer:
        "League Life is here to help! We are a resource guide for all things in your city. Whether you are just moving or have lived in the same place for years we will make it super easy to find resources for the every day needs in your life.",
    },
    {
      question: "Who is able to become a League Life member?",
      answer:
        "League Life is a private and exclusive membership app available only to individuals employed by an NBA organization and their families.",
    },
    {
      question: "Is League Life free?",
      answer:
        "There is no fee at this time to be a member of the League Life community.",
    },
    {
      question: "Can I invite other people to become a League Life Member?",
      answer:
          "Individuals employed by a NBA organization will be invited to become a member of League Life and be able to share that access with 2 individuals of their choice. The invitation to join will have to come from the employee of the NBA organization.",
    },
    {
      question: "If I want to find out more about sponsorship or partnership opportunities with League Life who should I contact?",
      answer:
          "We would love to hear from you! Please email info@leaguelife.com and we will make sure to get you to the proper contact.",
    },
    {
      question: "If I have a technical problem or issue with the app who can I contact?",
      answer:
          "Please let us know right away if you experience any technical issues by emailing info@leaguelife.com",
    },
  ];

  const questionGroups = faqs.map((question) => (
    <div className="question-group" key={question.question}>
      <h3>{question.question}</h3>
      <p>{question.answer}</p>
    </div>
  ));

  return (
    <>
      <h1>Frequently Asked Questions</h1>
      <br />
      <div className="question-groups">{questionGroups}</div>
    </>
  );
}

export default App;
