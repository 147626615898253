import React from "react";

import SampleVideo from "../video/_LL_Final_Splash_tiny.mp4";

function App() {
  return (
    <video
      id="bg-video"
      loop={true}
      muted={true}
      playsInline={true}
      autoPlay={true}
    >
      <source src={SampleVideo} type="video/mp4" />
    </video>
  );
}

export default App;
