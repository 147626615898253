import React, { useState, useEffect } from "react";
import Modal from "react-modal";

// marquees
import Marquee from "../components/marquee";

// images
import appIcon from "../images/appicon.png";
import mobileBall from "../images/mobile-ball.png";
import "../styles/app.scss";
import teamsDesktop from "../images/nbateams-desktop.jpg";
import teamsMobile from "../images/nbateams-mobile.jpg";
import basketball from "../images/basketball-homepage.png";
import appstore from "../images/appstore.png";
import phone from "../images/phone.png";
import radiant from "../images/radiant.png";
import animatedCards from "../images/animated-cards-small.gif";
import splashVideo from "../images/_LL_Final_Splash.mp4";

// import card1 from "../images/card1.png";
// import card2 from "../images/card2.png";
// import card3 from "../images/card3.png";
// import card4 from "../images/card4.png";
// import card5 from "../images/card5.png";
// import card6 from "../images/card6.png";

import card1 from "../images/card 1.gif";
import card2 from "../images/card 2.gif";
import card3 from "../images/card 3.gif";
import card4 from "../images/card 4.gif";
import card5 from "../images/card 5.gif";
import card6 from "../images/card 6.gif";
import sliderMobile from "../images/slider-mobile.gif";

import logogif from "../images/logo.gif";

import lifestyle1 from "../images/lux1.jpg";
import lifestyle2 from "../images/lux2.jpg";

import hero from "../images/hero.png";

Modal.setAppElement("#root");
function Homepage() {
  const [isScreenSmall, setScreenSize] = useState(window.innerWidth < 750);
  const [showTestimonialFrame, setTestimonialFrame] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth < 750);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTestimonialFrame((prevFrame) => (prevFrame % 2) + 1);
      // prevFrame % number of frames
    }, 2000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const selectFrame = (frame) => {
    setTestimonialFrame(frame);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const checkEmail = async (event) => {
    event.preventDefault();

    // clear any previous results
    setEmailExists(null);

    const response = await fetch(
      `https://us-central1-league-life-prod.cloudfunctions.net/doesEmailExist?email=${email}`
    );
    const data = await response.json();

    setEmailExists(data.exists);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            zIndex: 9999,
            backgroundColor: "#0a266d",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "10px",
            border: "none",
          },
        }}
        contentLabel="Check Status Modal"
      >
        <h1>Check Status</h1>
        <h1 onClick={closeModal} className="close-modal">
          X
        </h1>
        <div>
          {emailExists == null && (
            <form onSubmit={checkEmail}>
              <label>
                <input
                  type="email"
                  placeholder="E-mail"
                  className="email-modal-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <button type="submit" className="email-modal-btn">
                Check Email
              </button>
            </form>
          )}

          {emailExists !== null &&
            (emailExists ? (
              <div className="modal-message">
                <h2>
                  Congratulations! You already have access to League Life.
                </h2>
                <span>Download the app now using the link below.</span>

                <a
                  href="https://apps.apple.com/us/app/league-life/id1612547898"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-store-btn"
                >
                  <img
                    src={appstore}
                    alt="iOS app store link"
                    className="app-store-btn"
                  />
                </a>
              </div>
            ) : (
              <div class="modal-message">
                <h2>You have not yet been invited to use League Life.</h2>
                <a
                  href=" https://abi38wtuwac.typeform.com/to/Q3gHPxSM"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="email-modal-link"
                >
                  <div className="check-status-btn">Request Access</div>
                </a>
              </div>
            ))}
        </div>
      </Modal>
      <div className="homepage">
        {isScreenSmall && (
          <Marquee text="asdjhasifkj fjhds fdsjkf dskfh dskjhf " speed="20s" />
        )}

        {/* start page 1 */}
        <div
          className="page page-1"
          style={{
            backgroundColor: "#17296F",
            backgroundImage: isScreenSmall ? "" : `url(${hero})`,
          }}
        >
          {/* TODO : move up marquee bar for mobile? */}

          <div className="page-1-content-box-desktop">
            <div className="page-1-content-box-desktop-top">
              <img src={appIcon} alt="league life app icon" />
              <div className="page-1-content-box-desktop-top-text">
                <h1>League Life</h1>
                <span className="span-wrapped">
                  <span>Community resource app </span>
                  <span>exclusively for league members.</span>
                </span>
              </div>
            </div>
            <div className="page-1-content-box-desktop-bottom">
              <a
                href="https://apps.apple.com/us/app/league-life/id1612547898"
                target="_blank"
                rel="noopener noreferrer"
                className="app-store-btn"
              >
                <img
                  src={appstore}
                  alt="iOS app store link"
                  className="app-store-btn"
                />
              </a>
              <div className="check-status-btn" onClick={openModal}>
                Check Status
              </div>
            </div>
          </div>

          <div className="page-1-content-box-mobile">
            <img
              src={appIcon}
              alt="league life app icon"
              className="mobile-app-icon"
            />

            <h1>League Life</h1>
            <div className="league-life-mobile-content-box">
              <span className="mobile-description">
                League Life is a community resource app exclusively for league
                members.
              </span>
              <div className="league-life-mobile-content-box-buttons">
                <a
                  href="https://apps.apple.com/us/app/league-life/id1612547898"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-store-btn"
                >
                  <img
                    src={appstore}
                    alt="iOS app store link"
                    className="app-store-btn"
                  />
                </a>
                <div className="check-status-btn" onClick={openModal}>
                  Check Status
                </div>
              </div>
              <div className="bottom-text">
                Check your status or download now from app store
              </div>
            </div>
            <img
              src={mobileBall}
              alt="league life basketball image"
              className="mobile-basketball-image"
            />
          </div>
          {/* <img src={basketball} alt="basketball graphic" />
        <LLMarquee text="asdjhasifkj fjhds fdsjkf dskfh dskjhf " speed="20s" /> */}
        </div>
        {/* end page 1 */}
        {/* start marquee */}
        {!isScreenSmall && (
          <Marquee text="asdjhasifkj fjhds fdsjkf dskfh dskjhf " speed="20s" />
        )}
        {/* end marquee */}
        {/* start page 2 */}

        <div className="page page-2">
          <video className="video-background" autoPlay muted loop playsInline>
            <source src={splashVideo} type="video/mp4" />
          </video>
          <div className="testimonials">
            <span className="testimonial-label">TESTIMONIALS</span>

            {/* Frame 1 */}
            {showTestimonialFrame === 1 && (
              <div className="testimonial-frame">
                <h1>“League life saved my life”</h1>
                <div className="testimonial-name">
                  <span className="testimonial-name-top">Bre Walton</span>
                  <span className="testimonial-name-bottom">
                    Cleveland Cavaliers
                  </span>
                </div>
              </div>
            )}

            {/* Frame 2 */}
            {showTestimonialFrame === 2 && (
              <div className="testimonial-frame">
                <h2>"League Life has been great for our Jazz families."</h2>
                <div className="testimonial-name">
                  <span className="testimonial-name-top">
                    Whitli Godderidge
                  </span>
                  <span className="testimonial-name-bottom">
                    VP, Family Relations &amp; Basketball Operations, Utah Jazz
                  </span>
                </div>
              </div>
            )}

            {/* Frame 3 */}
            {/* {showTestimonialFrame === 3 && (
            <div className="testimonial-frame">
              <h1>"QUOTE 3 GOES HERE"</h1>
              <div className="testimonial-name">
                <span className="testimonial-name-top">Persons Name</span>
                <span className="testimonial-name-bottom">Team Name</span>
              </div>
            </div>
          )} */}

            {!isScreenSmall && (
              <div className="dots">
                <div
                  className="dot"
                  onClick={() => selectFrame(1)}
                  style={{
                    backgroundColor:
                      showTestimonialFrame === 1 ? "#0D1021" : "",
                  }}
                ></div>
                <div
                  className="dot"
                  onClick={() => selectFrame(2)}
                  style={{
                    backgroundColor:
                      showTestimonialFrame === 2 ? "#0D1021" : "",
                  }}
                ></div>
                {/* <div
                className="dot"
                onClick={() => selectFrame(3)}
                style={{
                  backgroundColor: showTestimonialFrame === 3 ? "#0D1021" : "",
                }}
              ></div> */}
              </div>
            )}
          </div>
        </div>
        {/* end page 2 */}

        {/* start page 3 */}

        <div className="page page-3" style={{ backgroundColor: "#17296F" }}>
          <h2>now Available for all nba teams</h2>
          <div className="nba-teams-container">
            {isScreenSmall ? (
              <img src={teamsMobile} alt="nba teams in a grid" />
            ) : (
              <img src={teamsDesktop} alt="nba teams in a grid" />
            )}
          </div>
          <h2>over 2000 hand-picked services</h2>
        </div>
        {/* end page 3 */}

        {/* start page 4 */}

        <div className="page page-4" style={{ backgroundColor: "#101B35" }}>
          <div className="page-4-header">
            <h1>community reccomendations</h1>
            <span>
              Stay connected and informed within this exclusive community.
              Discover personalized recommendations from your fellow League
              members, ensuring you're always in the know, whether in your
              current city or on the road.
            </span>
          </div>
          <img
            src={card1}
            alt="image of app ui - card"
            className="card card-1"
          />

          <img
            src={card2}
            alt="image of app ui - card"
            className="card card-2"
          />

          <img
            src={card3}
            alt="image of app ui - card"
            className="card card-3"
          />

          <img
            src={card4}
            alt="image of app ui - card"
            className="card card-4"
          />

          <img
            src={card5}
            alt="image of app ui - card"
            className="card card-5"
          />

          <img
            src={card6}
            alt="image of app ui - card"
            className="card card-6"
          />

          <img src={logogif} alt="animated logo" className="logo-gif" />
          <img
            src={sliderMobile}
            className="slider-mobile"
            alt="slider showing league life options"
          />
        </div>
        {/* end page 4 */}

        {/* start page 5 */}

        <div className="page page-5" style={{ backgroundColor: "#17296F" }}>
          <h1>every type of service</h1>
          <span>
            From top-notch dining experiences to exclusive amenities, our app
            offers a diverse range of services tailored to your needs. Embrace
            the convenience and luxury of accessing everything you desire in one
            place.
          </span>
          <img
            src={animatedCards}
            alt="animated ui element cards showing establishments"
            className="animated-cards"
          />
        </div>
        {/* end page 5 */}

        {/* start page 6 */}

        <div className="page page-6" style={{ backgroundColor: "#101B35" }}>
          <div className="book-text">
            <h1>book right in the app</h1>
            <span>
              Explore a comprehensive directory of services in your area and
              make reservations with ease, all within our app. Secure a table at
              your favorite restaurant or arrange for any service you desire.
            </span>
            <a
              href="https://apps.apple.com/us/app/league-life/id1612547898"
              target="_blank"
              rel="noopener noreferrer"
              className="app-store-btn"
            >
              <img
                src={appstore}
                alt="iOS app store link"
                className="app-store-btn"
              />
            </a>{" "}
          </div>
          <div className="book-image">
            <img src={phone} alt="cell phone mock up" className="phone" />
            <img
              src={radiant}
              alt="blue round graphic element"
              className="radiant"
            />
          </div>
        </div>
        {/* end page 6 */}

        {/* start page 7 */}

        <div
          className="page page-7"
          style={{ backgroundColor: isScreenSmall ? "#17296F" : "#101B35" }}
        >
          <div className="page-7-content">
            <div className="page-7-images">
              <img
                src={lifestyle2}
                alt="disover luxury image"
                className="discover-luxury"
              />
              <img
                src={lifestyle1}
                alt="disover luxury image"
                className="discover-luxury"
              />
            </div>

            <div className="page-7-text">
              <h1>discover luxury </h1>
              <span>
                Our app allows you to discover and reserve extraordinary
                experiences effortlessly, placing your favorite restaurant or
                venue just a tap away.
              </span>
            </div>
          </div>
        </div>
        {/* end page 7 */}

        {/* start page 8 */}

        <div className="page page-8" style={{ backgroundColor: "#17296F" }}>
          <h1>want to be included?</h1>
          <div className="check-status-button" onClick={openModal}>
            CHECK STATUS
          </div>
          <img
            src={basketball}
            alt="basketball graphic"
            className="bottom-basketball"
          />
        </div>
        {/* end page 8 */}
      </div>
    </>
  );
}

export default Homepage;
