import React from "react";

function App() {
  return (
    <div className="terms">
        <p><strong>Privacy Policy</strong></p>
        <p>&nbsp;</p>
        <p><strong>Last Updated: May 23, 2022</strong></p>
        <p>&nbsp;</p>
        <p>This website&nbsp;(the &ldquo;<strong>Site</strong>&rdquo;) is owned and made available by League Life LLC (&ldquo;<strong>LEAGUE LIFE</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;). This Privacy Policy describes the information collected by LEAGUE LIFE and its suppliers through the Site (&ldquo;<strong>Collected Information</strong>&rdquo;) and how that information is used and disclosed. This Privacy Policy also describes your choices regarding the use of your information, how you can correct inaccuracies in your information, and how we communicate changes to this Privacy Policy.</p>
        <p>&nbsp;</p>
        <p><strong>By accessing or using the Site, by accepting the Site&rsquo;s </strong><strong>Terms of Use</strong><strong>, or by entering into any other agreement with League Life that governs access to or use of the Site, you agree on behalf of yourself and any organization or company that you represent (together, &ldquo;you&rdquo;) that you have read and understand this Privacy Policy. If you do not agree with this Privacy Policy, do not access or use the Site.</strong></p>
        <p>&nbsp;</p>
        <p><strong>We reserve the right to update this Privacy Policy at any time. All updates will be effective immediately upon posting of the updated Privacy Policy on the Site. Material changes will be clearly posted on the Site or otherwise communicated to you.&nbsp;</strong></p>
        <p>&nbsp;</p>
        <p>This Privacy Policy is incorporated into and made a part of our Terms of Use and any other agreement that references this Privacy Policy or governs access to or use of the Site.</p>
        <p>&nbsp;</p>
        <p><strong>Table of Contents</strong></p>
        <p>&nbsp;</p>
        <ol>
            <li>Information We Collect and How We Collect It</li>
            <li>Information You Submit</li>
            <li>Other Information Collected</li>
            <li>How We Use and Share Collected Information</li>
            <li>Your Choices and Access</li>
            <li>Security</li>
            <li>Access by Children</li>
            <li>Third-Party Websites</li>
            <li>California Privacy Rights</li>
            <li>Contact Us</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
            <li><strong>Information We Collect and How We Collect it.</strong> The information we may collect generally falls into the following categories:</li>
        </ol>
        <ul>
            <li>Information that you voluntarily provide to us, for example, your contact information, payment information, and user-generated content.</li>
            <li>Information collected through your use of the Site, for example, account activity, data collected by the servers used to operate the Site (e.g., IP addresses and device information), data collected by analytics and advertising services, and data collected by cookies and tracking technologies.</li>
        </ul>
        <ol start="2">
            <li><strong>Information You Submit.</strong></li>
        </ol>
        <ul>
            <li><em>Personal Information.</em><strong><em> </em></strong>You can visit the Site without submitting your personal information to us, but<strong> </strong>you will be required to provide your personal information in order to sign up for an account through the Site (where that option is available), to contact us through the Site, or to use certain other features of the Site. We will collect any personal information from or about you that you choose to provide to us, which may include, without limitation, your name, physical address, telephone number, email address, and payment information. We will use your personal information as described below in this Privacy Policy, including to set up and administer your Site account (where applicable) and to communicate with you. Additionally, if you provide a mobile or other telephone number to us, you consent to receiving telephone calls and texts from us at that telephone number as described below, but only to the extent permitted by applicable law.</li>
            <li><em>Order &amp; Payment Information.</em><strong><em> </em></strong>To purchase, reserve, book, or pay for services through the Site, where that option is available, you must provide your contact information (e.g., name and shipping address) and payment information. All purchases made using the Site must be made by credit or debit card. League Life does not directly access, handle, or store credit or debit card information submitted through the Site. To make a purchase using the Site, you will need to provide your name and your credit or debit card type, number, expiration date, security code, and billing address. This information is used for billing purposes and to fill your orders. If we have trouble processing an order, we&rsquo;ll use this information to contact you.</li>
            <li><em>Comments.</em><strong><em> </em></strong>You are responsible for any data, information, images, messages, documents, and other content that you enter, submit, create, post, upload, transmit, or otherwise provide through or using the Site, including by submitting comments.&nbsp;</li>
            <li><em>User-Generated Content.</em><strong><em> </em></strong>You are responsible for any data, information, images, messages, documents, and other content that you enter, submit, create, post, upload, transmit, or otherwise provide through or using the Site. All user-generated content is provided at your own risk. We cannot guarantee that user-generated content you provide will not be viewed by unauthorized persons. You understand that, even after removal, copies of user-generated content you have provided may remain viewable in cached and archived pages and may have been copied or stored by Internet archives and other Site users. League Life will make user-generated content available, including publicly available, to other Site users and to the public, as applicable and at its sole discretion, and will use and disclose user-generated content for the purpose for which it was provided as indicated at the point of collection. For more information on user-generated content, please review our Terms of Use.</li>
            <li><em>Marketing Communications.</em><strong><em> </em></strong>If you sign up or otherwise opt in to receive service recommendations, promotions, or other communications from us, you will need to provide your email address or telephone number. We will use this information to send you League Life updates and promotional and other electronic communications. You may opt out of receiving updates and other emails from us at any time by following the unsubscribe instructions in the applicable email or text. We may use third-party providers to deliver these communications to you.</li>
        </ul>
        <p>&nbsp;</p>
        <ol start="3">
            <li><strong>Other Information Collected.<em> </em></strong>As you navigate and use the Site, certain information can be passively collected&mdash;that is, gathered without the user actively providing the information or being concurrently made aware of the collection of information&mdash;using various technologies. We passively collect a variety of types of information in a variety of ways, including:</li>
        </ol>
        <ul>
            <li><em>IP Addresses and Related Data.</em> The servers used to operate and provide the Site may collect data pertaining to you and the equipment, software, and communication methods you use to access the Internet and the Site, including Internet protocol (&ldquo;<strong>IP</strong>&rdquo;) addresses assigned to the computers and other devices from where you access the Internet, your Internet service provider (ISP), device ID numbers and unique identifiers, your media access control (MAC) address, your operating system, your computer screen resolution, your web browser type, the pages you access on the Site, the websites you access before and after visiting the Site, the length of time you spend on the Site, date and time stamps, clickstream data, your approximate geographic location, performance statistics, and usage data. League Life may use this information to administer the Site and its servers, to generate statistical information, to monitor and analyze Site traffic and usage patterns, to monitor and help prevent fraud, to investigate complaints and violations of our policies, and to improve the Site.</li>
        </ul>
        <p>We may combine this information with other Collected Information (including personal information) and information obtained from third parties for security reasons and to protect our rights or the rights of others. The suppliers that we use to provide the Site may collect information about your visits to the Site and other websites. Some of this information may be collected using cookies and similar tracking technologies as explained below under &ldquo;Cookies and Tracking Technologies.&rdquo;</p>
        <ul>
            <li><em>Analytics.</em> The Site uses third-party analytics tools (e.g., Google Analytics) to collect and process data about your use of the Site, including when you visit the Site, URLs of the websites that you visit prior to visiting the Site and when you visit those websites, and IP addresses assigned to the devices from where you access the Internet. Our analytics providers may set and read cookies to collect this data and your web browser will automatically send data collected by those cookies to our analytics providers. Our analytics providers use this data to provide us with reports that we will use to improve the Site&rsquo;s structure and content.</li>
        </ul>
        <p>For more information on how Google uses this data, visit <a href="https://policies.google.com/privacy">Google&rsquo;s Privacy Policy</a> and Google&rsquo;s page on <a href="https://policies.google.com/technologies/partner-sites">How Google uses data when you use our partners' sites or apps</a>. To prevent this data from being used by Google Analytics, follow the instructions to download and install the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a> for each browser you use. Using the Google Analytics Opt-out Browser Add-on will not prevent League Life from using other analytics tools and will not prevent data from being sent to the Site itself or to Google. For more information about how Google Analytics uses cookies to measure user interactions on websites, visit <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a>. You may disable cookies as discussed below, but that may impact your use and enjoyment of the Site.&nbsp;</p>
        <ul>
            <li><em>Advertising Networks, Personalized Advertising, Remarketing, and Retargeting.</em> We may also enable and implement the following Google Analytics Advertising Features on the Site: Remarketing with Analytics, Demographics and Interest reporting, Campaign Manager integration, Display &amp; Video 360 integration, Google Display Network (&ldquo;<strong>GDN</strong>&rdquo;) Impression Reporting, and Segments. Remarketing with Analytics uses Google Analytics cookies to serve advertisements to you across the Internet based on your visits to the Site. Demographics and Interest reporting uses a third-party cookie to collect information about our Site traffic by tracking users across websites and across time, which generates a report for us to better understand Site users. Campaign Manager integration allow us to view, analyze, and create remarketing lists using our Campaign Manager data in Google Analytics. Display &amp; Video 360 integration allows us to create remarketing lists in Google Analytics and have those lists available in Display &amp; Video 360. GDN Impression Reporting allows us to measure the impact of unclicked GDN Display ad impressions on website behavior and conversions. Segments allows us to isolate and analyze subsets of Site users by sorting our Google Analytics data.</li>
        </ul>
        <p>To opt out of remarketing advertising provided through Google, to customize your ad preferences, or to limit Google&rsquo;s collection or use this information, visit <a href="https://safety.google/">Google&rsquo;s Safety Center</a> and <a href="https://adssettings.google.com/anonymous?sig=ACi0TChyD4dbrzM0C0H2Y4qqhIgzsue2PsMlRsLltepRdxZO3z5mxe9Jucgjhqt1NFh9VB_Ghmj8DoveMMTZqO-ME9LA-Sx0HvwFKENFTBPVeMoto7cn2is&amp;hl=en">Google&rsquo;s Ad Settings</a> and follow <a href="https://support.google.com/ads/answer/2662922?hl=en&amp;ref_topic=7048998">Google&rsquo;s personalized ad opt-out instructions</a>. Opting out will not affect your use of the Site.</p>
        <p>To change your preferences with respect to certain online ads and to obtain more information about third-party ad networks and online behavioral advertising, please visit the <a href="https://optout.networkadvertising.org/?c=1">National Advertising Initiative Consumer opt-out page</a> or the <a href="https://youradchoices.com/">Digital Advertising Alliance Self-Regulatory Program</a>. Please remember that changing your settings with individual web browsers or ad networks will not necessarily carry over to other browsers or ad networks. As a result, depending on the opt-outs you request, you may still see our ads from time to time. Your device may also include a feature (&ldquo;Limit Ad Tracking&rdquo; on iOS or &ldquo;Opt Out of Interest-Based Ads&rdquo; on Android) that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes.</p>
        <ul>
            <li><em>Social Media.</em> The Site may allow you to connect and share information with social media platforms and we may be required to implement cookies, plug-ins, and APIs provided by those social media platforms in order to facilitate those communications and features. We may share information that you provide us or that we may collect about your use of the Site with those platforms and that information will be subject to their privacy policies. We encourage you to review the privacy policy of any social media platform that you use in connection with the Site. In addition, by choosing to use any third-party social media platform or choosing to share content or communications with any social media platform, you allow us to share information with the designated social media platform. We cannot control any policies or terms of such third-party platforms. As a result, we cannot be responsible for any use or disclosure of your information or content by third-party platforms, which you use at your own risk.</li>
            <li><em>Cookies and Tracking Technologies.</em> We use &ldquo;cookies&rdquo; on the Site. A cookie is a piece of data stored on a Site visitor&rsquo;s hard drive by the visitor&rsquo;s web browser. We use cookies to help us improve your access to the Site (for example, by remembering your contact and other information when you access or use the Site), to identify repeat visitors to the Site, to track how you access and use the Site, to learn when and how users visit the Site, to learn how popular Site pages are and aren&rsquo;t, to learn which search terms are used to find the Site, to learn which websites direct you to the Site, to help display certain information on the Site, and to improve your enjoyment of the Site.&nbsp;</li>
        </ul>
        <p>The Site may use both cookies that we implement and cookies implemented by our suppliers and other third parties. &ldquo;First-party&rdquo; cookies allow your browser to talk to the actual website that you are visiting, whereas &ldquo;third-party&rdquo; cookies allow your browser to talk to third-party websites, such as the source of an ad that appears on the website you are visiting or a third-party analytics provider.</p>
        <p>Most browsers automatically accept cookies. Browsers generally also allow users to manage cookies in the browser&rsquo;s settings. For example, a browser may allow you to reject cookies from certain websites, reject certain types of cookies regardless of the website, reject or disable all cookies from all websites, and/or delete cookies stored previously. Some browsers also give you the option of being notified every time a cookie is sent to your browser by a website. You can disable or limit cookies but doing so may impact your use and enjoyment of the Site and other websites. For example, the Site may not be able to be personalized for you, may no longer capture or remember your preferences or other choices you have made on the Site in the past, and may not remember your name or contact information.</p>
        <p>Changing your cookie preferences in one browser will not necessarily carry over to other browsers, so you may need to adjust your preferences each time you get a new computer, install a new browser, upgrade an existing browser, or alter or delete a browser&rsquo;s cookie file.</p>
        <p>Our suppliers may use cookies and tracking technologies, such as pixel tags, to track Site visitors across the Internet to understand how you get to the Site and for the analytics purposes disclosed in this Privacy Policy. We do not have access or control over these cookies and this Privacy Policy does not cover the use of third-party cookies.</p>
        <p>The types of information collected by a cookie and the purposes for which that information is used depends on the type of cookie. By way of illustration, the types of cookies used, the types of information collected by those cookies, and the purposes for which that information are used are described below. We may combine information collected by cookies with other Collected Information.</p>
        <p>A.<em> Operationally necessary cookies (also called essential cookies).</em> These are cookies that are required for the operation of the Site. For example, these cookies are required to identify irregular website behavior, prevent fraudulent activity, and improve security. They also allow users of the Site to make use of its functions. Without these cookies, features and services you have requested will not be able to be provided.</p>
        <p>B.<em> Functional cookies.</em> These cookies allow us to offer you enhanced functionality when accessing or using the Site. This may include to remembering choices you make, for example, remembering your preferences or settings, remembering if you reacted to something on or through the Site so that you are not asked to react to it again, remembering if you have used any feature of the Site before, remembering your username or preferences, remembering if you have used any feature of the Site before, restricting the number of times you are shown a particular advertisement, and enabling social media components. If functional cookies are disabled, various functions of the Site may be unavailable to you or may not work the way you want them to.</p>
        <p>C. <em>Performance cookies (also called analytical cookies).</em> These cookies assess the performance of the Site, including as part of our analytic practices to help us understand how visitors use and interact with the Site, for example, which pages on our websites that users visit most often. These cookies also enable us to personalize content and remember your preferences. These cookies help us improve the way our websites work and provide a better, personalized user experience.</p>
        <p>D.<em> Advertising or targeted cookies. </em>These cookies record your visits to the Site, the pages you visit on our websites, and the links you have clicked. They gather information about your browsing habits and remember that you have visited a particular website. League Life and its third-party advertising platforms or networks may use this information to make the Site and its content more relevant to your interests (this is sometimes called &ldquo;behavioral&rdquo; or &ldquo;targeted&rdquo; advertising and is further discussed above). These cookies are also used to limit the number of times you see an advertisement and to help measure the effectiveness of advertising campaigns. To find out more about interest-based ads and your choices, please visit the <a href="https://digitaladvertisingalliance.org/">Digital Advertising Alliance</a>, <a href="https://www.networkadvertising.org/">the Network Advertising Initiative</a>, <a href="https://iabeurope.eu/">the Interactive Advertising Bureau (IAB) Europe</a>, <a href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>, and <a href="https://www.youronlinechoices.com/">https://www.youronlinechoices.com/</a>.</p>
        <p>A cookie can either be a &ldquo;session&rdquo; cookie or a &ldquo;persistent&rdquo; cookie. Session cookies exist only for so long as you are visiting the applicable website. Session cookies are typically deleted or removed when you exit or quit your browser application. Persistent cookies exist for a set period of time, for example, six months or one year. Each time you visit a website that has implemented a persistent cookie, that cookie will remain active until its predetermined expiration date. You can also manually delete persistent cookies as discussed above.</p>
        <p>The Site may also use the following types of tracking technologies: web beacons (also called clear GIFs), flash cookies, and pixels (also called pixel tags). Web beacons are tiny graphics with unique identifiers that functions similar to how cookies function but, in contrast to cookies, web beacons are embedded invisibly on websites. Flash cookies collect and store information about your use of a website and are commonly used for advertisements and videos. Pixel tags can be placed on websites or within emails to track your interactions with those websites and when emails are opened.</p>
        <ul>
            <li><em>How We Respond to Do-Not-Track Signals.</em> Due to the automatic collection of data using cookies as described above, we do not honor &ldquo;do not track&rdquo; requests. For this reason, your selection of the &ldquo;Do Not Track&rdquo; option provided by your browser may not have any effect on our collection of information using cookies and other tracking technologies.</li>
        </ul>
        <p>&nbsp;</p>
        <ol start="4">
            <li><strong>How We Use and Share Collected Information.<em> </em></strong>In addition to the uses and sharing described above, League Life and its suppliers may use and disclose Collected Information as described below. We do not use, sell, rent, or otherwise disclose Collected Information except as stated in this Privacy Policy and as required or permitted by applicable law.</li>
        </ol>
        <ul>
            <li><em>Purpose Collected and Communication with You.</em><strong><em> </em></strong>We will use and share your personal information and other Collected Information for the purpose for which it was collected, for example, to process and communicate with you in connection with your purchases, your requests for information, and user-generated content you provide, to administer your Site account and League Life business activities, to provide customer support to Site users, and to provide you with information and communications that you request. User-generated content will be used for the purpose for which it was provided, including to make that content available to other users of the Site, if applicable. If you provide us with your telephone number, we may call you regarding your order or in response to your questions or comments. We may also use Collected Information to notify you of changes made to the Site (e.g., changes to our Terms of Use or this Privacy Policy).</li>
            <li><em>Promotional Communications.</em> League Life may use Collected Information to send you promotional communications about League Life and its services to the extent permitted by applicable law and in accordance with your preferences. In order to provide you with a personalized experience, these communications may be tailored to your preferences based on, for example, inferences we make using your visits to the Site or the links you click on in League Life emails. You may opt out of receiving promotional communications from us at any time by following the unsubscribe instructions contained in the email, where applicable, or by contacting us using the contact information provided below under &ldquo;Contact Us.&rdquo; Note that, even if you opt out of receiving promotional communications from League Life, you may still receive administrative communications from us with respect to your use of the Site.</li>
            <li><em>Sharing Collected Information with Our Suppliers.</em> We may disclose Collected Information to our and our affiliates&rsquo; respective suppliers and service providers as necessary for us to provide the Site and our products and services to you, to engage in the use and sharing of Collected Information as permitted by this Privacy Policy, and as may be otherwise required or permitted by applicable law. These third parties include, without limitation, our website and software management and hosting suppliers, payment processors, shipping and freight providers, online storefront providers, support service providers, cloud storage providers, information technology security providers, marketing and public relations service providers, and email service suppliers. Our suppliers are authorized to and may use and disclose Collected Information as necessary for them to provide the applicable products and services to us and as provided by their own privacy policies.</li>
            <li><em>Evaluation and Improvement of Our Offerings.</em> We may use and disclose Collected Information to analyze, develop, and improve the content, materials, and services that we provide; to inform marketing and communication plans and strategies; to understand use demographics and preferences; to analyze the effectiveness of the Site; and to evaluate user needs and customize Site content and the user experience.</li>
            <li><em>Data Aggregation.</em> To the extent permitted by applicable law, we may aggregate personal information, user-generated content, and other Collected Information and use that aggregate data for our business purposes. We may use aggregate data to understand Site users&rsquo; needs and usage patterns, to determine what kinds of products and services we can provide, and to improve and enrich our products and services. We may provide aggregate information to our affiliates, suppliers, and other third parties for these purposes. We may also combine Collected Information with other information in our possession for purposes of using information as described in this Privacy Policy.</li>
            <li><em>Lawful Processes; Security and Protection of Rights.</em> We may use and share Collected Information as required or permitted in accordance with subpoenas, court orders, valid law enforcement requests, governmental agency requests, and other legal processes. We may use Collected Information and share it with third parties if we believe doing so is necessary or appropriate to protect our rights or the rights of others, including disclosing information as necessary to protect our operations, to enforce or bring legal action in the event of a violation of our agreements or policies, or to pursue remedies or limit damages that we may sustain.</li>
            <li><em>Business Transactions.</em> If League Life undergoes a change in control, acquisition, merger, reorganization, or asset sale (in whole or in part), we may transfer, sell, share, or otherwise disclose Collected Information to the subsequent owner(s) or successor(s) of those transactions. We may also disclose Collected Information in connection with the evaluation of those transactions. Those owner(s), successor(s), and other recipients, as applicable, will be bound by this Privacy Policy as it applies to the information disclosed. Also, if League Life or any of its assets are acquired or if League Life goes out of business, enters bankruptcy, or goes through some other change in control or reorganization, Collected Information may be one of the assets transferred to or acquired by a third party.</li>
            <li><em>With Your Consent.</em> With your consent, we may use and share your personal information and other Collected Information in ways not specifically described in this Privacy Policy.</li>
            <li><em>Retention.</em> League Life will retain personal information for as long as it&rsquo;s needed for or otherwise serves the purposes outlined in this Privacy Policy, subject to applicable law.</li>
        </ul>
        <ol start="5">
            <li><strong>Your Choices and Access.</strong> We give you choices regarding our use and disclosure of your personal information. To access or update your personal information as it exists in our records, please contact us using the contact information below under &ldquo;Contact Us&rdquo;.</li>
            <li><strong>Security.<em> </em></strong>League Life does not guarantee that loss, misuse, or alteration of Collected Information will not occur, but we have implemented appropriate security measures to help protect against the loss, misuse, and alteration of information under our control. The storage and communication of information can never be completely secure, so we do not guarantee that communications or other information that you submit or that are otherwise provided to us will be completely secure. If you become aware of any breach of Site security or this Privacy Policy, please notify us as set forth below, immediately. If our security system is breached, we will notify you of the breach only if and to the extent required under applicable law.</li>
            <li><strong>Access by Children.<em> </em></strong>The Site is not directed at children under 13 years of age. League Life does not knowingly collect or use information from children under 13 through the Site.</li>
            <li><strong>Third-Party Websites.<em> </em></strong>The Site may link to, or be linked to, websites, or services not maintained or controlled by League Life. League Life is not responsible for the privacy policies or practices of any third parties or any third-party websites, applications, or services. This Privacy Policy does not apply to any third-party websites, applications, or services or to any personal or other information that you may provide to third parties. You should read the privacy policy for each website, application, and service that you visit or use.</li>
            <li><strong>California Privacy Rights. </strong>The California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;) grants California residents certain rights with regards to our collection of their personal information. These rights include:</li>
        </ol>
        <ul>
            <li><em>The right to know:</em> Consumers have the right to be informed of the categories of personal information collected; the categories of sources from which personal information is collected; the business or commercial purposes for collecting or selling personal information; the categories of third parties with whom personal information is shared; and the specific pieces of personal information we have collected about a consumer.</li>
            <li><em>The right to deletion:</em> Consumers have the right to request deletion of their personal information that has been collected.</li>
            <li><em>The right to opt out:</em> Consumers have the right to opt out of the sale of their personal information (as &ldquo;sale&rdquo; is defined in the CCPA).</li>
            <li><em>The right to nondiscrimination:</em> Consumers have the right not to be discriminated against due to their exercise of their CCPA rights. We do not discriminate against consumers for exercising their CCPA rights.</li>
        </ul>
        <p>California residents may exercise the above rights by submitting a verifiable request to us by contacting us, as set forth below, with &ldquo;Privacy&rdquo; in the Subject or Attention Line if by email or mail.&nbsp;</p>
        <p>&nbsp;</p>
        <p>Additionally, pursuant to California Civil Code Section 1798.83, residents of the State of California have the right to request from companies conducting business in California with whom the California resident has an established business relationship certain information relating to third parties to which the company has disclosed certain categories of personal information during the previous calendar year for those third parties&rsquo; direct marketing purposes. Alternatively, the law provides that a company that has a privacy policy that allows consumers to opt out of the sharing personal information with third parties for those third parties&rsquo; direct marketing purposes, as Tampico does, may instead provide information on how to exercise that opt-out right. As stated in our Privacy Policy, you may tell us you do not want us to share your personal information with third parties for their direct marketing purposes. If you would like to opt-out from this type of sharing with third parties, please email us at contacting us, as set forth below, with &ldquo;Opt Out&rdquo; in the Subject.&nbsp;</p>
        <p>&nbsp;</p>
        <ol start="10">
            <li><strong>Contact Us.<em> </em></strong>If you have any questions or concerns about this Privacy Policy or our collection, use, or disclosure of your information, please contact us at:</li>
        </ol>
        <p>&nbsp;</p>
    </div>
  );
}

export default App;
