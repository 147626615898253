import React from "react";

function App() {
  return (
    <footer className="brand-footer" style={{backgroundColor: '#101B35' }}>
      <div className="footer-left">
          <span>© {new Date().getFullYear()} LEAGUE LIFE™</span>
          <a href="mailto:info@leaguelife.com">Contact Us</a>
          <a href="/faq">FAQ</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms">Terms of Use</a>
      </div>
    </footer>
  );
}

export default App;
