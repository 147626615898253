import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import React from "react";

import Booking from "../pages/booking";
import Splash from "../pages/splash";
import Homepage from "../pages/homepage";
import Faq from "../pages/faq";
import Terms from "../pages/terms";
import Privacy from "../pages/privacy";
import Support from "../pages/support";
import LLLogo from "./logo";
import LLFooter from "./footer";
import LLVideo from "./video";
import LLImage from "./static-bg";
import EmailVerifiedSuccess from "../pages/emailVerifiedSuccess";

function App() {
  const location = useLocation();

  return (
    <div className="app">
      <div className="content">
        <div className="page-content">
          {location.pathname !== "/" && <LLLogo />}

          <Routes>
            <Route path="" element={<Homepage />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/support" element={<Support />} />
            <Route
              path="/email-verified-success"
              element={<EmailVerifiedSuccess />}
            />
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
        </div>
        <LLFooter />
      </div>
      {location.pathname === "/" ? "" : <LLImage />}
    </div>
  );
}

export default App;
