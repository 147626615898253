import React from "react";

import StaticBG from "../images/static-bg-pattern.png";

function App() {
  return (
    <img
      id="bg-image"
      src={StaticBG}
    >
    </img>
  );
}

export default App;
