import React from "react";
import { NavLink } from "react-router-dom";

import Brand from "../images/LL logo white@2x.png";

function App() {
  return (
    <div className="brand-logo">
      <NavLink to={"/"}>
        <img
          style={{
            maxWidth: "310px",
            maxHeight: "230px",
            marginBottom: "25px",
          }}
          src={Brand}
          width="100%"
          height="auto"
          alt="league life logo"
        />
      </NavLink>
    </div>
  );
}

export default App;
