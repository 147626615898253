import React from "react";

function App() {
  return (
    <div>
      <h2>
        Please open this link on a smartphone with the League Life app
        installed.
      </h2>
    </div>
  );
}

export default App;
