import React from "react";

function App() {
  return (
    <div className="support">
      <h2>Coming soon...</h2>
    </div>
  );
}

export default App;
