import React from "react";

import "../styles/app.scss";
import { useSearchParams } from "react-router-dom";

function Splash() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
        <>
          <div className="brand-caption splash">
            { !searchParams.get("error") ? <> <p>
              Thanks for verifying your email address.
            </p>
            <p>
              Please tap <a className="continue" target="_blank" href="https://leaguelife.com/continue" rel="noreferrer">
              here
            </a> or go
              back to the app to continue.
            </p></>
              : <p>
                  There was an error verifying your email address because the link was invalid or has expired. Please request a new verification link.
                </p> }

          </div>
        </>
    </div>
  );
}

export default Splash;
